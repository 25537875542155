const toFixed = (val, num) => {
  if (val || val == 0) {
    if (num) {
      val = Number(val);
      return val.toFixed(num);
    } else {
      val = Number(val);
      return val.toFixed(1);
    }
  } else {
    return "--";
  }
};

export default toFixed;
