import axios from "./axios";

export const postCaptcha = (phone, timestamp, clientKey) =>
  axios.post(
    "elec/captcha/send/captcha?phone=" +
      phone +
      "&timestamp=" +
      timestamp +
      "&clientKey=" +
      clientKey,
    true
  ); // 获取验证码
export const postVerifyCaptcha = (phone, verifyCode) =>
  axios.post(
    "elec/captcha/verify/captcha?phone=" + phone + "&verifyCode=" + verifyCode
  ); // 验证码是否正确
export const postForgetPassword = (phone, newPassword) =>
  axios.post(
    "elec/user/forget/password?phone=" + phone + "&newPassword=" + newPassword
  ); // 忘记密码修改

export const getAlertNum = () => axios.get("elec/project/alert-accident"); // 告警数量
export const getWeather = () => axios.get("elec/project/weather-time"); // 天气和时间
export const postPuzzle = (data) => axios.post("elec/user/slider-puzzle", data); // 验证码照片
export const login = (data) => axios.post("elec/user/login", data, true); // 登录接口 { data } 账号密码等参数
export const getMyInfo = () => axios.get("elec/user/myinfo"); // 获取本人信息
export const postPassword = (oldPassword, newPassword) =>
  axios.post(
    "elec/user/update-password?oldPassword=" +
      oldPassword +
      "&newPassword=" +
      newPassword
  ); // 修改密码
export const postAdmin = (data) => axios.post("elec/user/handover-admin", data); // 移交超管
export const getRoleList = () => axios.get("elec/user/all/role"); // 获取角色列表
export const postRole = (data) => axios.post("elec/user/role/add", data); // 新增角色
export const updateRole = (data) => axios.post("elec/user/role/update", data); // 编辑角色
export const deleteRole = (data) => axios.post("elec/user/role/delete/" + data); // 删除角色
export const getUser = (data) => axios.post("elec/user/list", data); // 用户列表
export const getDeptList = () => axios.get("elec/param/department-list"); // 获取部门列表
export const postUser = (data) => axios.post("elec/user/add", data); // 新增用户
export const deleteUser = (data) => axios.post("elec/user/delete/" + data); // 删除用户
export const updateUser = (data) =>
  axios.post("elec/user/update-message", data); // 编辑用户
export const postDept = (data) => axios.post("elec/param/add-department", data); // 新增部门
export const deleteDept = (data) =>
  axios.post("elec/param/delete-department/" + data); // 删除部门
export const updateDept = (data) =>
  axios.post("elec/param/update-department", data); // 编辑部门
export const getPhoneList = () => axios.get("elec/param/report-phone-list"); // 获取告警电话列表
export const postReport = (data) =>
  axios.post("elec/param/report-phone-add", data); // 新增告警负责人
export const getReportList = () =>
  axios.get("elec/param/report-phone-chooseable-list"); // 获取告警人列表

export const getVendorList = () => axios.get("elec/param/list/vendor"); // 获取厂商列表
export const postDevice = (data) => axios.post("elec/device/list", data); // 监测设备列表
export const getEquipment = (data) => axios.post("elec/equipment/list", data); // 电力设备列表
export const getTool = (data) => axios.post("elec/tool/list", data); // 工具设备列表
export const postTool = (data) => axios.post("elec/tool/add", data); // 新增工具
export const updateTool = (data) => axios.post("elec/tool/update", data); // 编辑工具
export const bulkUpdateTool = (data) =>
  axios.post("elec/tool/update-batch", data); // 批量编辑工具
export const deleteTool = (data) => axios.post("elec/tool/delete/" + data); // 删除工具台账
export const getCircuitlist = (id, data) =>
  axios.post("elec/circuit/list/" + id + "/" + data); // 删除电力设备
export const postEquipment = (data) => axios.post("elec/equipment/add", data); // 添加电力设备
export const updateEquipment = (data) =>
  axios.post("elec/equipment/update", data); // 修改电力设备
export const deleteEquipment = (data) =>
  axios.post("elec/equipment/delete/" + data); // 删除电力设备
export const getCircuitAmount = (data) =>
  axios.post("elec/circuit/amount/" + data); // 电路图tabs数量
export const getCircuitData = (data) => axios.post("elec/circuit/data", data); // 电路图趋势
export const getAlertList = (data) => axios.post("elec/alert/list", data); // 告警列表
export const getAlertExport = (data) =>
  axios.post("elec/alert/list/not/page", data); // 告警列表导出
export const alertStatus = (data) =>
  axios.post("elec/alert/record/status/" + data); // 告警确认
export const alertTendency = (data, type) =>
  axios.get("elec/alert/tendency/" + data + "/" + type); // 告警趋势
export const getAccident = (data) =>
  axios.post("elec/accident/accident/list", data); // 故障列表
export const getAccidentExport = (data) =>
  axios.post("elec/accident/list/not/page", data); // 故障列表导出
export const accidentStatus = (data) =>
  axios.post("elec/accident/record/status/" + data); // 故障确认
export const accidentTendency = (data, type) =>
  axios.get("elec/accident/tendency/" + data + "/" + type); // 故障趋势
export const getPopup = (id) => axios.get("elec/overview/popup/" + id); // 模型设备弹窗
export const getOverview = () => axios.get("elec/project/overview"); // 首页地图列表
export const getOverviewData = (id) => axios.get("elec/project/overview/" + id); // 首页企业弹窗
export const getModelInfo = (id) => axios.get("elec/overview/basi-info/" + id); // 模型首页基本信息
export const getSituation = (id) =>
  axios.get("elec/overview/device/general-situation/" + id); // 模型首页设备概况
export const getMonitoring = (id) =>
  axios.get("elec/overview/environmental-monitoring/" + id); // 模型首页环境监测
export const getHomeAlert = (id) => axios.get("elec/overview/alert/" + id); // 模型首页告警列表
export const getElectric = (id) =>
  axios.get("elec/overview/enterprise/electric/" + id); // 模型首页企业耗电量
export const getStation = (id) =>
  axios.get("elec/overview/station-state/" + id); // 模型首页配电室状态
export const getMonitor = (data) =>
  axios.get("elec/overview/equipment/monitor/" + data); // 模型首页电力监测 code
export const getMonitorData = (code) =>
  axios.get("elec/overview/circuit/data/" + code); // 模型首页电力监测 数据
export const getModelAccident = (id) =>
  axios.get("elec/overview/accident/" + id); // 模型首页故障列表
export const getHomeModelName = (id) =>
  axios.get("elec/overview/model-name/" + id); // 模型名称
export const getAlertModel = (id) =>
  axios.get("elec/overview/alert-model/" + id); // 模型告警
export const getPolling = (id) =>
  axios.get("elec/overview/polling-finish/" + id); // 巡检结算
export const getBoxChange = (id) => axios.get("elec/overview/box-change/" + id); // 巡检结算 箱变数据

export const getCurrent = () => axios.get("elec/common/countdown"); // 获取服务器时间

export const getModelType = () => axios.get("elec/param/model-display"); // 获取模型配置
export const postModelType = (type) =>
  axios.post("elec/param/model-display?display=" + type); // 设置模型配置

export const postList = (data) =>
  axios.post("elec/energy/monitoring/list", data); // 用能企业列表
export const postEcharts = (data) =>
  axios.post("elec/energy/monitoring/consume/time", data); // 用能企业图表
export const postConsume = (data) =>
  axios.post("elec/energy/monitoring/consume", data); // 用能企业概况

export const getTime = (data) =>
  axios.get("elec/common/now?timeFormat=" + data); //获取服务器时间

export const getMeterList = (data) =>
  axios.post("elec/meter/reading/list", data); // 抄表列表
export const getCircuit = () => axios.get("elec/meter/reading/circuit/num/1"); //获取服务器时间
