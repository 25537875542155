export const loginRouter = {
  path: "/login",
  name: "login",
  meta: {
    title: "登录",
  },
  component: () => import("../views/login.vue"),
};
// 导航显示的路由
export const homeRouter = {
  path: "/project",
  name: "",
  component: () => import("../views/menu/index.vue"),
  children: [
    {
      path: "home",
      name: "home",
      meta: { title: "项目概况" },
      component: () => import("../views/home/home.vue"),
      children: [
        {
          path: "role",
          name: "role",
          code: "101_201",
          meta: { title: "角色" },
          component: () => import("../views/home/role.vue"),
        },
        {
          path: "user",
          name: "user",
          code: "101_202",
          meta: { title: "用户" },
          component: () => import("../views/home/user.vue"),
        },
        {
          path: "dept",
          name: "dept",
          code: "101_203",
          meta: { title: "部门" },
          component: () => import("../views/home/dept.vue"),
        },
        {
          path: "phone",
          name: "phone",
          code: "101_204",
          meta: { title: "告警电话接听" },
          component: () => import("../views/home/phone.vue"),
        },
        {
          path: "model",
          name: "model",
          code: "999",
          meta: { title: "模型显示" },
          component: () => import("../views/home/model.vue"),
        },
      ],
    },
    {
      path: "company/:id",
      name: "company",
      meta: { title: "配电室" },
      component: () => import("../views/company/index.vue"),
      children: [
        {
          path: "faults",
          name: "faults",
          code: "102_201",
          meta: { title: "故障管理" },
          component: () => import("../views/company/faults.vue"),
        },
        {
          path: "alert",
          name: "alert",
          code: "102_201",
          meta: { title: "告警管理" },
          component: () => import("../views/company/alert.vue"),
        },
        {
          path: "power",
          name: "power",
          code: "102_203",
          meta: { title: "电力设备台账" },
          component: () => import("../views/company/power.vue"),
        },
        {
          path: "tools",
          name: "tools",
          code: "102_203",
          meta: { title: "工器具台账" },
          component: () => import("../views/company/tools.vue"),
        },
        {
          path: "monitor",
          name: "monitor",
          code: "102_203",
          meta: { title: "监测设备台账" },
          component: () => import("../views/company/monitor.vue"),
        },
      ],
    },
    {
      path: "seek/:id",
      name: "seek",
      meta: { title: "巡检" },
      component: () => import("../views/company/seek.vue"),
    },
  ],
};
export const routers = [loginRouter, homeRouter];
