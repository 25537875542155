import Vue from 'vue'
import { createStore } from 'vuex'
import { homeRouter } from '@/router/router'
import { router } from '@/router/index'
import Util from '@/utils/index';
import { getAlertNum } from '@/utils/api'
// 类似 Redux 中的建立状态树

export default createStore({
  
  // 1、 存储所有全局数据
  state: {
    info: localStorage.getItem('info') ? localStorage.getItem('info') : null,
    menuList: [],
    ledgerMenu: [],
    scale: 1,
    alertNumData: null,
  },
 
  // 2、 需要通过计算获取state里的内容获取的数据
  // 只能读取不可修改
  getters: {
   
  },
 
  //  3、定义对state的各种操作
  // why不直接实现在mutation里需要写到action里?
  // mtations不能执行异步操作。aq:从云端获取信息-->(等待云端反馈)更新到state异步操作
  // 因此说:对于异步操作需要放到action里，简单的直接赋值操作可以直接放到mutation里
  mutations: {
    // 缩放比例
    scale(state,data) {
      state.scale = data
    },
    // 登录成功 获取用户信息
    info(state,data) {
      localStorage.info = data
      state.info = data
    },
    // 项目概况路由
    menuList(state) {
      let unattendedRouter = homeRouter.children[0]
      let accessCode = localStorage.getItem('permissionList') ? JSON.parse(localStorage.getItem('permissionList')) : "";
      if(accessCode && accessCode.length != 0) {
        let menuList = [];
        unattendedRouter.children.forEach((item, index) => {
          if (Util.oneOf(item.code, accessCode)) {
            menuList.push(item);
          }
        });
        state.menuList = menuList;
      }
    },
    // 台账路由
    ledgerMenu(state) {
      let unattendedRouter = homeRouter.children[1]
      let accessCode = localStorage.getItem('permissionList') ? JSON.parse(localStorage.getItem('permissionList')) : "";
      if(accessCode && accessCode.length != 0) {
        let menuList = [];
        unattendedRouter.children.forEach((item, index) => {
          if(item.name != 'faults' && item.name != 'alert') {
            if (Util.oneOf(item.code, accessCode)) {
              menuList.push(item);
            }
          }
        });
        state.ledgerMenu = menuList;
      }
    },
    // 告警
    alertNumData(state, data) {
      state.alertNumData = data
    }
  },

  // 3、定义对state的各种操作
  // actions无法直接修改state，需要在mutations里更新
  // mutation不支持异步，所以需要在action里写api到url
  actions: {
    // 比说action定义了更新state的操作
    // 但是不可对其直接修改
    // 所有的修改操作必须放到mutations里
    // 异步获取告警数
    alertNum( { commit, state }) {
      getAlertNum().then( data => {
        // 同步到 mutation 函数,然后使用
        commit("alertNumData",data.result);
      })
    },
  },
})

