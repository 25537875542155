import { createRouter, createWebHistory } from 'vue-router'
import { routers } from './router';

export const router = createRouter({
  history: createWebHistory(),
  routes: routers
})
router.beforeEach((to, from, next) => {
  if(!localStorage.tokenData && to.name !== 'login'){
    next({ name: 'login' });
  } else if(to.path == '/') {
    next({ name: 'login' })
  } else {
    next();
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router