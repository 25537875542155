import { createApp } from "vue";
import "./font/style.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "./styles/css-dark.css";
import App from "./App.vue";
import router from "./router/index.js";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import * as ElIconList from "@element-plus/icons-vue";
import * as echarts from "echarts";
import store from "./store";
import "animate.css/animate.min.css";
import btn from "@/utils/focus";
import toFixed from "@/utils/toFixed";

const app = createApp(App);

// 创建全局方法
app.config.globalProperties.myToFixed = toFixed;

for (const name in ElIconList) {
  app.component(name, ElIconList[name]);
}
app
  .use(router)
  .use(btn)
  .use(store)
  .use(ElementPlus, { locale: zhCn })
  .mount("#app");
