import axios from "axios";
import { ElMessage, ElLoading } from "element-plus";
import router from "../router";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV == "development"
      ? "http://192.168.1.234:19953/"
      : process.env.VUE_APP_BASE_URL,
  timeout: 20000,
  headers: { "Content-Type": "application/json" },
});
//开始加载动画
var loadingText = null;
var loadingNum = 0; //初始化接口数量
var loadingTimeout;
export function startLoading() {
  loadingText = ElLoading.service({
    text: "加载中...", //加载中需要显示的文字
    background: "rgba(0,0,0,.5)", //背景颜色
  });
}
//结束加载动画
export function endLoading(val) {
  loadingNum = 0;
  if (loadingText) {
    loadingText.close();
    loadingTimeout = undefined;
  }
}

// 请求拦截器
instance.interceptors.request.use(
  function (config) {
    if (!loadingTimeout) {
      loadingTimeout = setTimeout(() => {
        loadingNum++;
        startLoading();
      }, 500);
    }
    return config;
  },
  function (error) {
    clearTimeout(loadingTimeout);
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
// respone拦截器
instance.interceptors.response.use(
  (response) => {
    loadingNum--;
    if (loadingNum <= 0) {
      clearTimeout(loadingTimeout);
      //结束加载动画
      endLoading();
    }
    return response;
  },
  (error) => {
    clearTimeout(loadingTimeout);
    endLoading();
    return Promise.reject(error);
  }
);
export default {
  get: function (url, data = {}) {
    instance.defaults.headers.common["Authorization"] = localStorage.tokenData;
    return new Promise((resolve, reject) => {
      instance
        .get(url, data)
        .then((response) => {
          if (response.data.code === 200) {
            resolve(response.data);
          } else if (response.data.code === 401) {
            ElMessage.closeAll();
            ElMessage.error(response.data.info);
            localStorage.clear();
            router.push({ name: "login" });
          } else {
            ElMessage.closeAll();
            ElMessage.error(response.data.info);
          }
        })
        .catch((error) => {
          if (error.message.includes("timeout")) {
            ElMessage.closeAll();
            ElMessage.error("网络异常, 请求超时", 0);
          } else {
            ElMessage.closeAll();
            ElMessage.error("服务器出错了,请稍后重试", 0);
          }
          reject(error);
        });
    });
  },
  post: function (url, data, login) {
    instance.defaults.headers.common["Authorization"] = localStorage.tokenData;
    return new Promise((resolve, reject) => {
      instance
        .post(url, data)
        .then((response) => {
          if (login) {
            resolve(response.data);
          }
          if (response.data.code === 200) {
            resolve(response.data);
          } else if (response.data.code === 401) {
            ElMessage.closeAll();
            ElMessage.error(response.data.info);
            localStorage.clear();
            router.push({ name: "login" });
          } else {
            ElMessage.closeAll();
            ElMessage.error(response.data.info);
          }
        })
        .catch((error) => {
          if (error.message.includes("timeout")) {
            ElMessage.closeAll();
            ElMessage.error("网络异常, 请求超时", 0);
          } else {
            ElMessage.closeAll();
            ElMessage.error("服务器出错了,请稍后重试", 0);
          }
          reject(error);
        });
    });
  },
};
