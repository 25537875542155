<template>
  <div class="menu-list" ref="ScaleBox" :style="{width: width + 'px',height: height + 'px'}">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        scale: 0,
        width: 1920,
        height: 1080,
      }
    },
    mounted() {
      //以1920*1080设计稿 为标准尺寸进行自动缩放
      this.setScale();
      window.addEventListener("resize", this.debounce(this.setScale,100));
    },
    methods: {
      getScale() {
        //根据屏幕的变化适配的比例
        // 固定好16：9的宽高比，计算出最合适的缩放比
        const {width, height} = this;
        const scale = window.innerWidth / window.innerHeight < width / height ? (window.innerWidth / width) : (window.innerHeight / height);
        return scale
      },
      setScale() {
        // 获取到缩放比例，设置它
        this.scale = this.getScale();
        this.$store.commit("scale",this.scale)
        var rootElement = document.documentElement;
        rootElement.style.setProperty('--scale',this.scale);
        if (this.$refs.ScaleBox) {
          this.$refs.ScaleBox.style.transform = 'scale('+ this.scale +') translate(-50%, -50%)';
          let baseWidth = window.innerWidth;
          let baseHeight = window.innerHeight;
          let appStyle = this.$refs.ScaleBox.style;
          let realRatio = baseWidth/baseHeight
          let designRatio = 16/9
          let scaleRate = baseWidth/1920
          if(realRatio > designRatio){
            scaleRate = baseHeight/1080
          }
          appStyle.width = `${baseWidth/scaleRate}px`
          appStyle.height = `${(baseHeight/scaleRate)}px`
        }
      },
      debounce(fn, delay) {
        const delays = delay || 500;
        let timer;
        return function () {
          const th = this;
          const args = arguments;
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
          }, delays);
        };
      },
    }
  }
</script>
<style lang="scss">
:root {
  --scale: 0.1;
}
@font-face {
  font-family: myNum;
  src: url('../public/BAHNSCHRIFT.TTF');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: HM;
  src: url('../public/HM.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: HR;
  src: url('../public/HR.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: PM;
  src: url('../public/YS.TTF');
  font-weight: normal;
  font-style: normal;
}
.menu-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  transform-origin: 0 0;
  top: 50%;
  left: 50%;
}
#app {
  height: 100%;
  width: 100%;
  touch-action: none;
  font-family: HR;
  margin: 0;
  padding: 0;
}
h1,h2,h3,p,div,span {
  margin: 0px;
  padding: 0px;
}
.el-dialog {
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%);
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
    border-radius:6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius:6px;
    background:rgba(0,0,0,0.1);
    -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background:rgba(0,0,0,0.1);
  }
}
</style>
