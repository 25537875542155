// 按钮元素鼠标移出以后失去焦点
export default {
  install: (app) => {
    const fun = function (evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN" || target.nodeName == "I") {
        target = evt.target.parentNode;
      }
      target.blur();
    };
    app.directive("btn", {
      mounted(el) {
        el.addEventListener("mouseout", fun);
      },
      unmounted(el) {
        el.removeEventListener("mouseout", fun);
      },
    });
  },
};
